"use client";

import type { ApplicationPhase, Location } from "@/cms/schema";
import { PropsWithChildren, useEffect, useState } from "react";

interface Props {
  location: Location;
}

export default function ApplicationPhaseInfo({ location }: Props) {
  const [currentTime, setCurrentTime] = useState<Date>();

  useEffect(() => {
    setCurrentTime(new Date());
  }, []);

  if (!currentTime && location.application_phases.length === 0) {
    return null;
  }

  if (!currentTime) {
    return <Card />;
  }

  const applicationPhases = location.application_phases;
  const currentPhase = getCurrentPhase(currentTime, applicationPhases);
  const nextPhase = getNextPhase(currentTime, applicationPhases);

  let description;

  // Berlin Time Formatter without "CET" and without time
  const berlinTimeFormatter = new Intl.DateTimeFormat("en-DE", {
    timeZone: "Europe/Berlin",
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  if (currentPhase) {
    const endDate = new Date(currentPhase.end);
    description =
      "Application phase open until " + berlinTimeFormatter.format(endDate);
    if (location.city === "Valencia") {
      description =
        "Given flooding: Application Phase extended until 8 December 2024";
    }
  } else {
    const startDate = new Date(nextPhase.start);
    description =
      "Next application phase opens on " +
      berlinTimeFormatter.format(startDate);
  }

  let applyURL: string | undefined;
  if (location.city === "Valencia") {
    applyURL = "https://forms.gle/Udjp99PuDBVRQ9vB8";
  }

  return (
    <Card className="block h-auto py-4">
      {currentPhase && <ApplyButton href={applyURL} />}
      <div className="px-6 text-center text-gray-700">{description}</div>
    </Card>
  );
}

// Component to display the card structure
function Card({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <div
      className={`-mb-2 flex h-24 flex-col items-center justify-center gap-1 bg-gray-300 ${className}`}
    >
      {children}
    </div>
  );
}

// Function to get the current application phase
function getCurrentPhase(
  currentTime: Date,
  applicationPhases: ApplicationPhase[],
) {
  return applicationPhases.find((phase) => {
    const startTime = new Date(phase.start);
    const endTime = new Date(phase.end);
    return currentTime >= startTime && currentTime <= endTime;
  });
}

// Function to get the next application phase
function getNextPhase(
  currentTime: Date,
  applicationPhases: ApplicationPhase[],
) {
  const sortedPhases = sortApplicationPhases(applicationPhases);
  for (let phase of sortedPhases) {
    const start = new Date(phase.start);
    if (start > currentTime) {
      return phase;
    }
  }
  return sortedPhases[0];
}

// Function to sort application phases by their start date
function sortApplicationPhases(applicationPhases: ApplicationPhase[]) {
  return applicationPhases.sort((a, b) => {
    const aStart = new Date(a.start);
    const bStart = new Date(b.start);
    return aStart.getTime() - bStart.getTime();
  });
}

// Apply button component with a blinking effect
function ApplyButton({ href }: { href?: string }) {
  if (!href) {
    href = "https://application.cdtm.com";
  }

  return (
    <a href={href} className="flex flex-row items-center justify-center gap-2">
      <Blink />
      <div className="text-lg font-semibold underline md:text-xl">
        Apply now
      </div>
    </a>
  );
}

// Blinking effect component
function Blink() {
  return (
    <span className="relative flex h-3 w-3">
      <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-blue opacity-75" />
      <span className="relative inline-flex h-3 w-3 rounded-full bg-blue" />
    </span>
  );
}
